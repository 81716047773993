export default defineNuxtRouteMiddleware(async (to) => {
  // do not execute middleware on server
  if (import.meta.server) {
    return
  }

  // nuxt app
  const { $actor } = useNuxtApp()

  // router
  const router = useRouter()

  const lastStepCompleted = $actor.core.storage.getUniversal(
    'tutorMatchingLastStepCompleted'
  )

  const routeParams = to.params

  switch (lastStepCompleted) {
    case undefined: {
      if (to.fullPath !== `/tutor-matching/${routeParams.matchId}/`) {
        await router.replace({ path: `/tutor-matching/${routeParams.matchId}/` })
      }
      break
    }
    case 1: {
      if (to.fullPath.includes('submit')) {
        await router.replace({ path: `/tutor-matching/${routeParams.matchId}/availability` })
      }
      break
    }
  }
})
